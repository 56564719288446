import React, { Component } from 'react';
import Axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Row,
    Col,
    Table,
    Container,
    CardHeader

} from "reactstrap"

import Header from "components/Headers/Header.js";

export default class ViewSingleCoupon extends Component {


    constructor(props) {
        super(props)



        //state elements
        this.state = {
            code: "",
            type: "",
            id: "",
            loading: false,

        }
    }


    async componentDidMount() {
        //set loading to true to show spinner
        this.setState({ loading: true })
        //get token from local storage
        if (!window.localStorage.getItem("token")) {
            //redirect to Login if token not found in local storage
            this.props.history.push("/");
        }

        let id = window.location.href;
        let arr = id.split('/');
        let actualId = arr[5];


        //Base Url
        let url = "https://staging.beacon.travel";

        //create axios instance
        const authAxios = Axios.create({
            baseURL: url,

        });

        //Make Api call
        await authAxios.get(`/admin/coupon/${actualId}`)
            .then((response) => {
                let data = response.data.data
                if (data._id == actualId) {
                    //set state val._id , val.name
                    this.setState({
                        id: data._id,
                        code: data.code,
                        type: data.type,
                        loading: false
                    })
                }

            })
            .catch(error => {
                alert(error)
                this.setState({ loading: false })
            });


    }


    //delete a particular
    deleteHandler(id) {
        //set loading to true
        this.setState({ loading: true });

        //Base Url
        let url = "https://staging.beacon.travel";

        //create axios instance
        const authAxios = Axios.create({
            baseURL: url,

        });


        //Make Api call
        authAxios.delete(`admin/coupon/${id}`)
            .then((response) => {
                alert("Coupon Deleted Successfully!")
                this.setState({ loading: false })
                window.location.href = "/admin/list-coupon"
            })
            .catch(error => {
                alert(error)
                this.setState({ loading: false })
            });

    }




    render() {
        if (this.state.loading) {
            return (
                <Spinner />
            )
        } else {
            return (

                <>
                    <Header />
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <h3 className="mb-0">Coupon Details</h3>
                                    </CardHeader>
                                    <Table className="align-items-center" responsive >
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Coupon Code</th>
                                                <th scope="col">Coupon Type</th>
                                                <th scope="col">Action</th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                <tr key={this.state.id} id="tr">
                                                    <td>{this.state.code}</td>
                                                    <td>{this.state.type}</td>
                                                    <td>
                                                        <Button size="lg" color="danger" onClick={() => this.deleteHandler(this.state.id)}>
                                                            <span className="btn-inner--icon">
                                                                <i className="fas fa-trash " />
                                                            </span>
                                                            <span className="btn-inner--text">Delete </span>
                                                        </Button>
                                                    </td>
                                                </tr>

                                            }
                                        </tbody>
                                    </Table>


                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>

            )
        }
    }


}