
import Index from "views/Index.js";
// import Profile from "views/examples/Profile.js";
import AddCoupon from "views/examples/AddCoupon";
import ListCoupon from "views/examples/ListCoupon.js";
import ListServiceCategory from "views/examples/ListServiceCategory.js";
// import EditServiceCategory from "views/examples/EditServiceCategory.js";
import ViewSingleCoupon from "views/examples/ViewSingleCoupon.js";
// import ViewCommission from "views/examples/ViewCommission.js";
// import AddAdmin from "views/examples/AddAdmin";
// import Maps from "views/examples/Maps.js";
// import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/add-coupon",
    name: "Add Coupon",
    icon: "ni ni-planet text-blue",
    component: AddCoupon,
    layout: "/admin",
  },
  {
    path: "/list-coupon",
    name: "View Coupon",
    icon: "ni ni-planet text-blue",
    component: ListCoupon,
    layout: "/admin",
  },
  // {
  //   path: "/list-service-category",
  //   name: "View Service Category",
  //   icon: "ni ni-planet text-blue",
  //   component: ListServiceCategory,
  //   layout: "/admin",
  // },
  {
    path: "/single-coupon",
    icon: "",
    component: ViewSingleCoupon,
    layout: "/admin",
  },
  // {
  //   path: "/commission",
  //   name: "Commission",
  //   icon: "ni ni-planet text-blue",
  //   component: ViewCommission,
  //   layout: "/admin",
  // },


  {
    path: "/login",
    // name: "Login",
    // icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },

  // {
  //   path: "/edit-service-category",
  //   // name: "View Service Category",
  //   // icon: "ni ni-planet text-blue",
  //   component: EditServiceCategory,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-admin",
  //   // name: "View Service Category",
  //   // icon: "ni ni-planet text-blue",
  //   component: AddAdmin,
  //   layout: "/admin",
  // },
];
export default routes;
